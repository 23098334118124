<template>
  <div class="container">
    <Head-section></Head-section>
    <b-card><h1>You cancelled the payment.</h1></b-card>
  </div>
</template>
<script>
import HeadSection from "./StoreHeadSection.vue";
// import HeadSection from "./HeadSection.vue";

export default {
  name: "PayCancle",
  components: { HeadSection }
};
</script>

